import {
  Select,
  FormControl,
  InputLabel
} from '@mui/material'

const FormikSelectField = ({ field, form: { errors, touched }, ...props }) => {
  const isError = touched[field.name] && !!errors[field.name]

  let labelId = props.name + '-label';
  let label = props.label ? props.label : ''

  return (<FormControl fullWidth>
    <InputLabel id={labelId} >{label}</InputLabel>
    <Select
        labelId={labelId}
        {...field}
        error={isError}
        {...props}
        label={label}
      />
  </FormControl>)
}

export default FormikSelectField

import { Box } from "@mui/system"
import { Typography, List, ListSubheader, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { useQuery } from "react-query"
import { useEffect, useState } from "react"
import config from "../../config"
import Loader from "../../components/Loader"
import { formatPrice } from "../../functions"

const Payment = ({ values }) => {
  const [working, setWorking] = useState(false)
  const [openIndex, setOpenIndex] = useState(false)

  const { isLoading, data: paymentMethods } = useQuery('payment-methods', () => {
    return fetch(`${config.serverUrl}/payments/methods`)
      .then(res => res.json())
  })

  useEffect(() => {
    if (!isLoading && openIndex === false && paymentMethods?.length === 1) {
      setOpenIndex(0)
    }
  }, [isLoading, openIndex, paymentMethods?.length])

  const initializePayment = async (method, data) => {
    setWorking(true)

    try {
      const payload = {
        subscriptionId: values.subscription.id,
        amount: values.subscription.costs,
        method: method,
        data: { issuer: null }
      }
  
      const response = await fetch(`${config.serverUrl}/payments`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json'
        }
      })
  
      const payment = await response.json()
  
      if (payment && payment.redirectUrl) {
        window.location = payment.redirectUrl
      } else {
        setWorking(false)
      }
    } catch (error) {
      console.log(error)
      setWorking(false)
    }
  }

  const handleMethodClick = async (index) => {
    try {
      const method = paymentMethods[index]
      await initializePayment(method.key)
    } catch (e) {
      console.log('err',e)
    }
  }

  if (isLoading) {
    return <>Bezig met ophalen van data</>
  }

  if (working) {
    return <Loader/>
  }

  return <>
    <Box sx={{ p: 2 }}>
      <Typography variant="h6">Direct online betalen</Typography>
      <Typography variant="body2">Je reservering is pas definitief als je hebt betaald. Gelukkig kan je hieronder heel makkelijk online betalen.</Typography>
    </Box>
    <Box sx={{ p: 2 }}>
      <Typography variant="body1" style={{ fontWeight: 'bold' }}>Te betalen: {formatPrice(values.subscription.costs)}</Typography>
    </Box>
    <List
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Kies een betaalmethode
        </ListSubheader>
      }
    >
      {
        paymentMethods.map((method, index) =>
          <ListItemButton
            key={method.key}
            onClick={() => handleMethodClick(index)}
          >
            <ListItemIcon>
              <img src={`/images/${method.key}.png`} alt={method.label} width="24" />
            </ListItemIcon>
            <ListItemText
              primary={method.label}
              secondary={method.costs ? `+ ${formatPrice(method.costs)}` : "Geen extra kosten"}
            />
            </ListItemButton>
        )
      }
    </List>
  </>
}

export default Payment

import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useState } from "react"
import { dateFromString, humanReadableDate } from "../../functions"
import { useQuery } from "react-query"
import config from "../../config"
import useSettings from "../../hooks/useSettings"
import Loader from "../../components/Loader"
import { useSearchParams } from "react-router-dom"

const OptionLabel = ({ date, startTime, endTime, description }) => {
  return <>
    {humanReadableDate(dateFromString(date))} van {startTime.substring(0, 5)} tot {endTime.substring(0, 5)}
    {description ? <Typography variant="caption" component="div">{description}</Typography> : null}
  </>
}

const SelectDate = ({ onNext, values }) => {
  const [searchParams, ] = useSearchParams();
  const eventDate = searchParams.get("hmsid")

  const [selected, setSelected] = useState(values?.openDay?.id ? values?.openDay?.id : eventDate ? eventDate : null)

  const settings = useSettings()

  const { isLoading, data } = useQuery('opendays', () => {
    return fetch(`${config.serverUrl}/opendays/available`)
      .then(res => res.json())
  }, { retry: false })

  const openDays = data

  const onSelectDate = (event, newValue) => {
    setSelected(newValue)
  }

  const goNext = () => {
    if (onNext) {
      if (selected === 'none') {
        onNext({ openDay: null })
      } else {
        const _id = parseInt(selected)
        onNext({ openDay: openDays.find(day => day.id === _id) })
      }
    }
  }

  if (!settings.homeTitle) {
    return <Loader/>
  }


  var description = '';
  if((openDays && openDays.length > 0) || !settings.homeBodyNoDays){
    description = settings.homeBody;
  }
  else{
    description = settings.homeBodyNoDays;
  }

/*  const empty_paragraph_regex = /\<p\>(?:\s*|\s*\<br\\?\>\s*)?\<\/p\>/gi;
  description = description.replace(empty_paragraph_regex, '');*/

  description = description.replace(/<p>/g, '<p style="margin:0;">');

  return <>
    <Typography variant="h4">
      {settings.homeTitle}
    </Typography>

    {isLoading
      ? <Typography variant="paragraph">Bezig met ophalen van data</Typography>
      : openDays && <Box sx={{ mt: 2 }}>
          <Typography variant="paragraph">
            <div dangerouslySetInnerHTML={{ __html: description, }}></div>
          </Typography>
          <FormControl component="fieldset" sx={{ mt: 4 }}>
            {openDays.length > 0 && <FormLabel component="legend">Kies een van onderstaande dagen</FormLabel>}
            <RadioGroup aria-label="gender" name="radio-buttons-group" value={selected} onChange={onSelectDate}>
              {openDays.map(openDay => (
                <FormControlLabel
                  key={openDay.id}
                  value={openDay.id}
                  control={<Radio />}
                  label={<OptionLabel date={openDay.date} startTime={openDay.startTime} endTime={openDay.endTime} description={openDay.description}/>}
                  // label={`${humanReadableDate(dateFromString(openDay.date))} van ${openDay.startTime.substring(0, 5)} tot ${openDay.endTime.substring(0, 5)}`}
                />
              ))}
              {openDays.length > 0
                ? <FormControlLabel 
                    value="none" 
                    control={<Radio />} 
                    sx={{ alignItems: 'flex-start' }}
                    componentsProps={{ typography: { sx: { pt: 1 } } }}
                    label="De data komen mij helaas niet goed uit. Graag laat ik mijn gegevens achter om een bericht te ontvangen wanneer er nieuwe data worden toegevoegd." 
                  />
                : <FormControlLabel 
                    value="none" 
                    control={<Radio />} 
                    label="Ik heb interesse in de Hotelschool open dagen en ik wil graag op de hoogte gehouden worden." 
                  />
              }
            </RadioGroup>
          </FormControl>
        </Box>
    }

    <Box sx={{ textAlign: 'right', mt: 2 }}>
      <Button variant="contained" onClick={goNext} disabled={!selected}>Volgende</Button>
    </Box>
  </>
}

export default SelectDate

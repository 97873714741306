import DetailsForm from "../../components/subscribe/DetailsForm"
import WaitingListForm from "../../components/subscribe/WaitingListForm"
import SubscriptionsClient from '../../clients/Subscriptions'
import { useState } from "react"
import { Box } from "@mui/system"
import { Alert, Button } from "@mui/material"

const subscriptionsClient = new SubscriptionsClient()

const Details = ({ onNext, onPrevious, values }) => {
  const [alert, setAlert] = useState(null)

  const onDetailsSubmit = async (newValues) => {
    const data = {
      opendayId: values.openDay.id,
      firstName: newValues.firstName,
      middleName: newValues.middleName,
      lastName: newValues.lastName,
      email: newValues.email,
      nrOfPersons: newValues.nrOfPersons,
      previousEducation: newValues.previousEducation,
      examYear: newValues.examYear,
      dietNotes: newValues.dietNotes,
      optIn: newValues.optIn,
      phone: newValues.phone,
    }

    const subscription = await subscriptionsClient.create(data)

    if (!subscription?.id) {
      if (subscription?.error === 'no_capacity') {
        setAlert({
          severity: 'error',
          message: 'Helaas, de open dag is inmiddels a vol. Klik op \'Vorige\' om een andere open dag te selecteren.'
        })
      } else {
        setAlert({
          severity: 'error',
          message: subscription?.error || 'Er is een onbekende fout opgetreden'
        })
      }
    } else {
      onNext({ ...values, ...newValues, subscription })
    }
  }

  const onWaitingListSubmit = async (newValues) => {
    const data = {
      name: newValues.name,
      email: newValues.email,
    }

    await subscriptionsClient.createQueue(data)

    onNext({ ...values, ...newValues })
  }

  return <>
    {alert &&
      <Alert severity={alert.severity} sx={{ mb: 2 }}>{alert.message}</Alert>
    }
    {!alert || alert.severity !== 'error'
      ? <>
          {values.openDay
            ? <DetailsForm
                pickedDay={values.openDay}
                onPrevious={onPrevious}
                onSubmit={onDetailsSubmit}
              />
            : <WaitingListForm
                onPrevious={onPrevious}
                onSubmit={onWaitingListSubmit}
              />
          }
        </>
      : <Box sx={{ mt: 2, display: 'flex' }}>
          <Button variant="outlined" onClick={onPrevious}>Vorige</Button>
        </Box>
    }
  </>
}

export default Details
